<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Schedules</h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button class="mb-5 add-button" @click="addSchedule()">
          Add
        </b-button>
      </b-col>
    </b-row>
    <vuetable
      ref="schedulestable"
      :api-mode="false"
      :fields="fields"
      :data="schedules"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit schedule"
          @click="editSchedule(props.rowIndex)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove schedule"
          @click="deleteSchedule(props.rowIndex)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2";

export default {
  data() {
    return {
      fields: [
        {
          name: `title`,
          title: "Title",
          sortField: "Title",
        },
        { name: "actions", sortField: "Actions" },
      ],
      isLoading: false,
    };
  },
  components: {
    Vuetable,
  },
  async created() {
    await this.doFetchAll();
  },
  methods: {
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Schedule/fetchAll");
      this.isLoading = false;
    },
    addSchedule() {
      this.$router.push({ path: "schedule-detail" });
    },
    editSchedule(index) {
      this.$router.push({ path: "schedule-detail", query: { index: index } });
    },
    async deleteSchedule(index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove schedule?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.schedules.splice(index, 1);
        await this.$store.dispatch("Schedule/update", this.schedules);
      }
      this.isLoading = false;
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    schedules() {
      return this.$store.state.Schedule.all;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>